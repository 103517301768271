import { MediaPlayerInstance } from "@vidstack/react";
import { MutableRefObject, SetStateAction } from "react";
import Player from "video.js/dist/types/player";
import createStore from "zustand";

export type MediaStore = {
  // state
  player: MutableRefObject<MediaPlayerInstance>;
  playerVideoJs: MutableRefObject<Player>;
  isPlayerReady: boolean;

  // actions
  setPlayer: (player: MutableRefObject<MediaPlayerInstance>) => void;
  setPlayerVideoJs: (player: MutableRefObject<Player>) => void;
  setIsPlayerReady: (isReady: SetStateAction<boolean>) => void;
};

export const useMediaStore = createStore<MediaStore>((set) => ({
  // state
  player: null,
  playerVideoJs: null,
  isPlayerReady: false,

  // actions
  setPlayer(player) {
    set({ player });
  },
  setPlayerVideoJs(player) {
    set({ playerVideoJs: player });
  },
  setIsPlayerReady: (isReady) =>
    set((state) => ({
      isPlayerReady:
        typeof isReady === "function" ? isReady(state.isPlayerReady) : isReady,
    })),
}));
